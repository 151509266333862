import React, { useState, useEffect } from "react";
import Airtable from "airtable";
import Flashcard from "./Flashcard";
import _ from "underscore";
import "./App.scss";

const App = () => {
  const [data, setData] = useState([]);
  const [offset, setOffset] = useState(0);

  useEffect(() => {
    const getData = async () => {
      Airtable.configure({ apiKey: "keym3QvaqbcsM8tSk" });
      const base = Airtable.base("appgVH5qFvkt1xItu");
      const dataArray = [];
      await base("Sentences")
        .select({
          filterByFormula:
            "IS_BEFORE({test_date}, DATEADD(TODAY(), 1, 'days'))",
          view: "Study",
        })
        .eachPage((records, fetchNextPage) => {
          records.forEach(({ fields, id }) =>
            dataArray.push({ id, ...fields })
          );
          fetchNextPage();
        });
      setData(_.shuffle(dataArray));
    };
    getData();
  }, []);

  const nextCard = () => {
    setOffset((prev) => prev + 1);
  };

  return (
    <div className="app">
      <div className="counter">
        {offset + 1} / {data.length}
      </div>
      {data.length > 0 && <Flashcard card={data[offset]} nextCard={nextCard} />}
    </div>
  );
};

export default App;
