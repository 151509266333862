import React, { useState, useEffect } from "react";
import Airtable from "airtable";
import moment from "moment";
import XRegExp from "xregexp";
import { FaTimes, FaCheck, FaRegCaretSquareUp } from "react-icons/fa";

const Flashcard = ({ card, nextCard }) => {
  const [flipped, setFlipped] = useState(false);
  const { id, japanese, english, srs } = card;
  Airtable.configure({ apiKey: "keym3QvaqbcsM8tSk" });
  const base = Airtable.base("appgVH5qFvkt1xItu");

  useEffect(() => {
    const handleKeyDown = (e) => {
      if (["d", "ArrowRight"].includes(e.key) && flipped) increaseSrsLevel();
      if (["a", "ArrowLeft"].includes(e.key) && flipped) decreaseSrsLevel();
      if (["w", "s", "ArrowUp", "ArrowDown"].includes(e.key))
        setFlipped((prev) => !prev);
    };
    window.addEventListener("keydown", handleKeyDown);
    return () => window.removeEventListener("keydown", handleKeyDown);
  }, [flipped]);

  const increaseSrsLevel = () => {
    base("Sentences").update(id, {
      srs: srs ? srs + 1 : 2,
      test_date: moment()
        .add(Math.pow(2, srs || 0), "days")
        .format("YYYY-MM-DD"),
    });
    setFlipped(false);
    nextCard();
  };
  const decreaseSrsLevel = () => {
    base("Sentences").update(id, {
      srs: 1,
      test_date: moment().add(1, "days").format("YYYY-MM-DD"),
    });
    setFlipped(false);
    nextCard();
  };

  const sentenceWithoutFurigana = () => {
    const regex = XRegExp("\\「.*?」");
    return XRegExp.replace(japanese, regex, "", "all");
  };
  const sentenceWithFurigana = () => {
    const regex = XRegExp("(\\p{Han})+");
    return {
      __html: XRegExp.replace(japanese, regex, "<ruby><rb>$&</rb>", "all")
        .replace(/「/g, "<rt>")
        .replace(/」/g, "</rt></ruby>"),
    };
  };

  return (
    <>
      {flipped ? (
        <div className="flashcard">
          <div dangerouslySetInnerHTML={sentenceWithFurigana()} />
          <div>{english}</div>
        </div>
      ) : (
        <div className="flashcard">
          <div>{sentenceWithoutFurigana()}</div>
        </div>
      )}
      <div className="controls">
        <button onClick={decreaseSrsLevel} disabled={!flipped}>
          <FaTimes color={"#F4B6BB"} />
        </button>
        <button onClick={() => setFlipped(true)} disabled={flipped}>
          <FaRegCaretSquareUp />
        </button>
        <button onClick={increaseSrsLevel} disabled={!flipped}>
          <FaCheck color={"#C7E9D1"} />
        </button>
      </div>
    </>
  );
};
export default Flashcard;
